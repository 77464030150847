import React, { useState } from 'react';

const EmailModal = ({ closeModal, sendEmail, format }) => {
    const [email, setEmail] = useState('');

    const handleSend = () => {
        if (email) {
            sendEmail(email);
            closeModal();
        } else {
            alert('Please enter an email address');
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <h2>Email {format.toUpperCase()}</h2> {/* Use format prop here */}
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                />
                <button onClick={handleSend}>Send</button>
            </div>
        </div>
    );
};

export default EmailModal;
