import React, { useState, useEffect } from 'react';
import './EMICalculator.css';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EmailModal from "./EmailModal";

const EMICalculator = () => {
    const [loanAmount, setLoanAmount] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [tenureYears, setTenureYears] = useState('');
    const [tenureMonths, setTenureMonths] = useState('');
    const [emiData, setEmiData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentFormat, setCurrentFormat] = useState('');

    useEffect(() => {
        document.title = "EMI Calculator";
    }, []);

    const formatNumberIndian = (num) => {
        if (isNaN(num) || num === '') return '';
        let [integerPart, decimalPart] = num.toString().split('.');
        let lastThree = integerPart.slice(-3);
        let rest = integerPart.slice(0, -3);

        if (rest !== '') lastThree = ',' + lastThree;
        let formattedInteger = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;

        return formattedInteger + (decimalPart ? '.' + decimalPart : '');
    };

    const unformatNumberIndian = (numStr) => parseFloat(numStr.replace(/,/g, ''));

    const parseInterestRate = (value) => parseFloat(value.replace('%', '').trim()) || 0;

    const formatInterestRate = (rate) => rate ? `${rate}%` : '';

    const validateFields = () => {
        const newErrors = {};
        if (!loanAmount) newErrors.loanAmount = "Loan Amount cannot be empty!";
        if (!interestRate) newErrors.interestRate = "Interest Rate cannot be empty!";
        if (!tenureYears && !tenureMonths) newErrors.tenureYears = "Tenure cannot be empty!";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const calculateEMI = () => {
        if (!validateFields()) return;

        const P = unformatNumberIndian(loanAmount);
        const r = parseInterestRate(interestRate) / 12 / 100;
        const years = parseFloat(tenureYears) || 0;
        const months = parseFloat(tenureMonths) || 0;
        const n = years * 12 + months;

        if (n <= 0) {
            alert('Tenure must be greater than 0');
            return;
        }

        const EMI_amount = Math.round(P * r * Math.pow(1 + r, n) / (Math.pow(1 + r, n) - 1));
        let outstandingPrincipal = P;
        const emiDetails = [];
        let cumulativeInterestPaid = 0;
        let cumulativePrincipalPaid = 0;

        for (let i = 1; i <= n; i++) {
            const interest = Math.round(outstandingPrincipal * r);
            const principal = EMI_amount - interest;
            const emi_no = i;
            outstandingPrincipal -= principal;
            cumulativeInterestPaid += interest;
            cumulativePrincipalPaid += principal;
            const totalEmiPaid = EMI_amount * i;
            const loanPaidPercent = ((P - outstandingPrincipal) / P) * 100;
            const currentMonth = new Date(startDate);
            currentMonth.setMonth(currentMonth.getMonth() + i - 1);
            const formattedMonthYear = currentMonth.toLocaleDateString('en-GB', { year: 'numeric', month: 'short' }).replace(' ', '-');
            const year = currentMonth.getFullYear();

            if (outstandingPrincipal < 10) {
                outstandingPrincipal = 0;
            }

            emiDetails.push({
                year,
                monthYear: formattedMonthYear,
                emiNumber: emi_no,
                emiAmount: EMI_amount,
                principalPaid: Math.round(principal),
                interestPaid: Math.round(interest),
                totalInterestPaid: cumulativeInterestPaid,
                totalPrincipalPaid: cumulativePrincipalPaid,
                totalEmiPaid: Math.round(totalEmiPaid),
                outstandingPrincipal,
                loanPaidPercent: loanPaidPercent.toFixed(2) + '%'
            });
        }
        setEmiData(emiDetails);
    };

    const exportToCSV = () => {
        const csvData = emiData.map(row => {
            const formattedRow = {};
            Object.keys(row).forEach(key => {
                formattedRow[headersMapping[key] || key] = row[key];
            });
            return formattedRow;
        });

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'emi_data.csv';
        a.click();
    };

    const exportToXLS = () => {
        const dataToExport = emiData.map(row => {
            const mappedRow = {};
            for (const key in row) {
                mappedRow[headersMapping[key] || key] = row[key];
            }
            return mappedRow;
        });

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headers = Object.keys(dataToExport[0]);
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let colNum = 0; colNum < headers.length; colNum++) {
            const cellAddress = XLSX.utils.encode_cell({ c: colNum, r: range.s.r });
            ws[cellAddress] = {
                v: headers[colNum],
                s: { alignment: { horizontal: 'center' } }
            };
        }

        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = XLSX.utils.encode_cell({ c: C, r: R });
                if (!ws[cellAddress]) continue;
                ws[cellAddress].s = { alignment: { horizontal: 'center' } };
            }
        }

        ws['!cols'] = headers.map(() => ({ wch: 10 }));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'EMI_Data');

        XLSX.writeFile(wb, 'emi_data.xlsx');
    };

    const handleLoanAmountChange = (e) => {
        const value = e.target.value;
        const formattedValue = value.replace(/[^0-9]/g, ''); // Allow only digits
        
        if (!isNaN(formattedValue)) {
            setLoanAmount(formatNumberIndian(formattedValue));
        
            // Clear the error if the input is valid
            if (errors.loanAmount) {
                setErrors(prevErrors => ({ ...prevErrors, loanAmount: '' }));
            }
        } else {
            setLoanAmount(value); // Keep the original input if invalid
        }
    };    

    const handleInterestRateChange = (event) => {
        let value = event.target.value;

        if (value.endsWith('%')) {
            value = value.slice(0, -1);
        }

        const formattedValue = value.replace(/[^0-9.]/g, '');
        if (formattedValue.split('.').length > 2) {
            return;
        }

        setInterestRate(formattedValue);

        if (formattedValue && !isNaN(formattedValue) && errors.interestRate) {
            setErrors(prevErrors => ({ ...prevErrors, interestRate: '' }));
        }
    };

    const handleTenureYearsChange = (e) => {
        const value = e.target.value;
        const formattedValue = value.replace(/[^0-9]/g, ''); // Allow only digits
        setTenureYears(formattedValue);
        if (formattedValue || tenureMonths) {
            if (errors.tenureYears) {
                setErrors(prevErrors => ({ ...prevErrors, tenureYears: '' }));
            }
        }
    };
    
    const handleTenureMonthsChange = (e) => {
        const value = e.target.value;
        const formattedValue = value.replace(/[^0-9]/g, ''); // Allow only digits
        setTenureMonths(formattedValue);
        if (formattedValue || tenureYears) {
            if (errors.tenureYears) {
                setErrors(prevErrors => ({ ...prevErrors, tenureYears: '' }));
            }
        }
    };    

    const clearForm = () => {
        setLoanAmount('');
        setInterestRate('');
        setTenureYears('');
        setTenureMonths('');
        setEmiData([]);
        setErrors({});
    };

    const openModal = (format) => {
        setCurrentFormat(format);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const sendEmail = (email) => {
        if (currentFormat === 'csv') {
            console.log(`Sending CSV to ${email}`);
        } else if (currentFormat === 'xls') {
            console.log(`Sending XLS to ${email}`);
        }
    };

    const headersMapping = {
        year: 'Year',
        monthYear: 'Month-Year',
        emiNumber: 'EMI No.',
        emiAmount: 'EMI Amount(₹)',
        principalPaid: 'Principal Paid (₹)',
        interestPaid: 'Interest Paid (₹)',
        totalInterestPaid: 'Total Interest Paid (₹)',
        totalPrincipalPaid: 'Total Principal Paid (₹)',
        totalEmiPaid: 'Total Amount Paid (₹)',
        outstandingPrincipal: 'Pending Principal (₹)',
        loanPaidPercent: 'Loan Paid (%)',
    };

    return (
        <div className="emi-calculator">
            <h2>Home Loan - EMI Calculator</h2>
            <form className="emi-form" onSubmit={(e) => { e.preventDefault(); calculateEMI(); }}>
                <table className="form-table">
                    <tbody>
                        <tr>
                            <td className="label-cell">Loan Amount:</td>
                            <td className="input-cell">
                                {errors.loanAmount && <p className="error-message">{errors.loanAmount}</p>}
                                <input
                                    type="text"
                                    value={loanAmount}
                                    onChange={handleLoanAmountChange}
                                    style={{ borderColor: errors.loanAmount ? 'red' : '' }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="label-cell">Interest Rate (% per annum):</td>
                            <td className="input-cell">
                                {errors.interestRate && <p className="error-message">{errors.interestRate}</p>}
                                <input
                                    type="text"
                                    value={formatInterestRate(interestRate)}
                                    onChange={handleInterestRateChange}
                                    style={{ borderColor: errors.interestRate ? 'red' : '' }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="label-cell">Tenure:</td>
                            <td className="input-cell tenure-container">
                                {errors.tenureYears && <p className="error-message">{errors.tenureYears}</p>}
                                <div className="tenure-fields">
                                    <input
                                        type="number"
                                        placeholder="Years"
                                        value={tenureYears}
                                        onChange={handleTenureYearsChange}
                                        style={{ borderColor: errors.tenureYears ? 'red' : '' }}
                                    />
                                    <input
                                        type="number"
                                        placeholder="Months"
                                        value={tenureMonths}
                                        onChange={handleTenureMonthsChange}
                                        style={{ borderColor: errors.tenureYears ? 'red' : '' }}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="label-cell">Loan Starts From:</td>
                            <td className="input-cell">
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    dateFormat="MMM-yyyy"
                                    showMonthYearPicker
                                    className="calendar"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="button-container">
                    <button type="button" onClick={calculateEMI} className="green-button">Calculate EMI</button>
                    <button type="button" onClick={clearForm} className="red-button">Clear</button>
                </div>
            </form>

            {isModalOpen && <EmailModal closeModal={closeModal} sendEmail={sendEmail} format={currentFormat} />}
            {emiData.length > 0 && (
                <>
                    <div className="export-section">
                        <button type="button" onClick={exportToCSV}>Download CSV</button>
                        <button type="button" onClick={exportToXLS}>Download XLS</button>
                        <button type="button" onClick={() => openModal('csv')}>Email CSV</button>
                        <button type="button" onClick={() => openModal('xls')}>Email XLS</button>
                    </div>
                    <table className="emi-table">
                        <thead>
                            <tr>
                                <th colSpan="6">Monthly (₹)</th>
                                <th colSpan="5">Cumulative (₹)</th>
                            </tr>
                            <tr>
                                <th>Year</th>
                                <th>Month-Year</th>
                                <th>EMI No.</th>
                                <th>EMI Amount(₹)</th>
                                <th>Principal Paid (₹)</th>
                                <th>Interest Paid (₹)</th>
                                <th>Total Interest Paid (₹)</th>
                                <th>Total Principal Paid (₹)</th>
                                <th>Total Amount Paid (₹)</th>
                                <th>Pending Principal (₹)</th>
                                <th>Loan Paid (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emiData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.year}</td>
                                    <td>{row.monthYear}</td>
                                    <td>{row.emiNumber}</td>
                                    <td>{formatNumberIndian(row.emiAmount)}</td>
                                    <td>{formatNumberIndian(row.principalPaid)}</td>
                                    <td>{formatNumberIndian(row.interestPaid)}</td>
                                    <td>{formatNumberIndian(row.totalInterestPaid)}</td>
                                    <td>{formatNumberIndian(row.totalPrincipalPaid)}</td>
                                    <td>{formatNumberIndian(row.totalEmiPaid)}</td>
                                    <td>{formatNumberIndian(row.outstandingPrincipal)}</td>
                                    <td>{row.loanPaidPercent}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default EMICalculator;
